// // ProtectedRoute.jsx
// import { useAuth0 } from '@auth0/auth0-react';
// import { Navigate, Outlet, useLocation } from 'react-router-dom';
// import LoadingScreen from '../../components/Common/LoadingScreen';

// const ProtectedRoute = ({ allowedEmails = [], restrictedEmails = [] }) => {
//   const { isAuthenticated, isLoading, user } = useAuth0();
//   const location = useLocation();

//   if (isLoading) {
//     return <LoadingScreen />;
//   }

//   if (!isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }

//   // Email-based access control
//   const userEmail = user?.email || '';
  
//   // If email is in restricted list, only allow specific routes
//   if (restrictedEmails.includes(userEmail)) {
//     const allowedPaths = ['/lender-iq']; // Add other paths if needed
//     if (!allowedPaths.includes(location.pathname)) {
//       return <Navigate to="/lender-iq" replace />;
//     }
//   }

//   // If email must be in allowed list (alternative approach)
//   if (allowedEmails.length > 0 && !allowedEmails.includes(userEmail)) {
//     return <Navigate to="/not-authorized" replace />;
//   }

//   return <Outlet />;
// };

// export default ProtectedRoute;


import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '../../components/Common/LoadingScreen';

interface ProtectedRouteProps {
  restrictedEmails?: string[];
  allowLenderIqForAll?: boolean;
}

const ProtectedRoute = ({ 
  restrictedEmails = [],
  allowLenderIqForAll =true
}: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const location = useLocation();
  const userEmail = user?.email?.toLowerCase() || ''; 

  if (isLoading) return <LoadingScreen />;
  if (!isAuthenticated) return <Navigate to="/" replace />;

  // Normalize emails for comparison
  const normalizedLenderEmails = restrictedEmails.map(email => email.toLowerCase());
  const isLender = normalizedLenderEmails.includes(userEmail);

  // Lender access control
  if (isLender && location.pathname !== '/lender-iq') {
    return <Navigate to="/lender-iq" replace />;
  }

  // Only restrict /lender-iq if explicitly configured
  if (!allowLenderIqForAll && !isLender && location.pathname === '/lender-iq') {
    return <Navigate to="/contacts" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;