// import { useAuth0 } from "@auth0/auth0-react";
// import { useEffect, useState } from "react";
// import removeAuthToken from "../../services/removeAuthHeader";
// import { useNavigate, useLocation } from "react-router-dom";
// import Logout from "../_pages/_Logout/Logout";

// const Sidebar = () => {
//   const sideMenu = [
//     { id: 1, text: "Case Study", url: "/case-study" },
//     { id: 2, text: "Download Syllabus", url: "/syllabus-download" },
//     { id: 3, text: "Book a demo", url: "/book-a-demo" },
//     { id: 4, text: "EBook", url: "/ebook" },
//     { id: 5, text: "Whitepaper", url: "/whitepaper" },
//     // {id: 6, text: "AI Assessment", url: "/ai-assessment"},
//     { id: 7, text: "Subscribe", url: "/subscribe" },
//     { id: 8, text: "Event", url: "/event" },
//     { id: 9, text: "Contact", url: "/contacts" },
//     { id: 10, text: "Assets", url: "/assets" },
//     { id: 11, text: "Podcasts", url: "/podcasts" },
//     { id: 12, text: "Lender IQ", url: "/lender-iq" },

//   ];

//   const navigate = useNavigate();

//   const location = useLocation();

//   return (
//     <aside>
//       <div className="logo">
//         <img src="/images/logo.svg" alt="logo" />
//       </div>
//       <ul className="nav">
//         {sideMenu.map((val) => (
//           <li key={val.id}>
//             <a
//               className={`link ${
//                 location.pathname === val.url ? "active" : ""
//               }`}
//               onClick={() => navigate(val.url)}
//             >
//               <span>{val.text}</span>
//             </a>
//           </li>
//         ))}
//       </ul>
//       <div className="logout">
//         <Logout />
//       </div>
//     </aside>
//   );
// };

// export default Sidebar;


import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logout from "../_pages/_Logout/Logout";
import { LENDER_EMAILS } from "../../routes";
import LoadingScreen from "./LoadingScreen";

const Sidebar = () => {
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredMenu, setFilteredMenu] = useState<Array<{id: number, text: string, url: string}>>([]);

  // Full menu items
  const allMenuItems = [
    { id: 1, text: "Case Study", url: "/case-study" },
    { id: 2, text: "Download Syllabus", url: "/syllabus-download" },
    { id: 3, text: "Book a demo", url: "/book-a-demo" },
    { id: 4, text: "EBook", url: "/ebook" },
    { id: 5, text: "Whitepaper", url: "/whitepaper" },
    { id: 7, text: "Subscribe", url: "/subscribe" },
    { id: 8, text: "Event", url: "/event" },
    { id: 9, text: "Contact", url: "/contacts" },
    { id: 10, text: "Assets", url: "/assets" },
    { id: 11, text: "Podcasts", url: "/podcasts" },
    { id: 12, text: "Lender IQ", url: "/lender-iq" },
  ];

  useEffect(() => {
    if (!isLoading && user?.email) {
      const userEmail = user.email.toLowerCase();
      const isLender = LENDER_EMAILS.some(email => 
        email.toLowerCase() === userEmail
      );

      // Filter menu based on user type
      const menu = isLender 
        ? allMenuItems.filter(item => item.url === "/lender-iq")
        : allMenuItems;

      setFilteredMenu(menu);
    }
  }, [user, isLoading]);

  console.log(filteredMenu)

  if (isLoading) {
    return (
      <aside>
        <div className="logo">
          <img src="/images/logo.svg" alt="logo" />
        </div>
        <div className="loading-placeholder">
          Loading menu...
        </div>
      </aside>
    );
  }

  return (
    <aside>
      <div className="logo">
        <img src="/images/logo.svg" alt="logo" />
      </div>
      <ul className="nav">
        {filteredMenu.map((val) => (
          <li key={val.id}>
            <a
              className={`link ${
                location.pathname === val.url ? "active" : ""
              }`}
              onClick={() => navigate(val.url)}
              style={{ cursor: 'pointer' }}
            >
              <span>{val.text}</span>
            </a>
          </li>
        ))}
      </ul>
      <div className="logout">
        <Logout />
      </div>
    </aside>
  );
};

export default Sidebar;
