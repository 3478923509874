// import axios from "axios";

// const deleteLenderDataByID = (params: string): Promise<any> => {
//     return axios.delete(`lender-iq-form/${(params)}`);
//   };
  
  
//   export default deleteLenderDataByID;

import axios from "axios";

type DeleteType = "lender" | "form" | "executiveBrief"; // Add more types as needed

const deleteEndpoints: Record<DeleteType, string> = {
  lender: "lender-iq-form",
  form: "forms",
  executiveBrief:"ai-in-trumpWorld"
};

const deleteByID = (type: DeleteType, id: string): Promise<any> => {
  if (!deleteEndpoints[type]) {
    return Promise.reject(new Error(`Delete type "${type}" is not supported`));
  }
  
  return axios.delete(`${deleteEndpoints[type]}/${id}`);
};

export default deleteByID;
