import {Routes, Route} from "react-router-dom";
import Callback from "../components/_pages/_Login/Callback";
import Forms from "../components/_pages/Forms/Forms";
import {Login} from "../components/_pages/_Login/Login";
import Contacts from "../pages/Contact";
import Subscribe from "../pages/Subscribe";
import Syllabus from "../pages/Syllabus";
import CaseStudy from "../pages/CaseStudy";
import Whitepaper from "../pages/Whitepaper";
import EBook from "../pages/EBook";
import AIAssessemnt from "../pages/AIassessment";
import NRF from "../pages/NRF";
import BookADemo from "../pages/BookADemo";
import PodcastsPage from "../pages/Podcasts";
import AssetsPage from "../pages/Assets";
import LenderIq from "../pages/LenderIq";
import ProtectedRoute from "../pages/ProtectedRoutes/ProtectedRoutes";

export const LENDER_EMAILS = [ "momin@fusemachines.com"];

export const Router = () => {
  return (
    // <Routes>
    //   <Route path="/" element={<Login />} />
    //   <Route path="/callback" element={<Callback />} />
    //   <Route path="/forms" element={<Forms />} />
    //   <Route path="/contacts" element={<Contacts />} />
    //   <Route path="/book-a-demo" element={<BookADemo />} />
    //   <Route path="/subscribe" element={<Subscribe />} />
    //   <Route path="/syllabus-download" element={<Syllabus />} />
    //   <Route path="/case-study" element={<CaseStudy />} />
    //   <Route path="/whitepaper" element={<Whitepaper />} />
    //   <Route path="ebook" element={<EBook />} />
    //   <Route path="ai-assessment" element={<AIAssessemnt />} />
    //   <Route path="event" element={<NRF />} />
    //   <Route path="podcasts" element={<PodcastsPage />} />
    //   <Route path="/assets" element={<AssetsPage />} />
    //   <Route path="/lender-iq" element={<LenderIq />} />
    // </Routes>

    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/callback" element={<Callback />} />

      {/*Only lender emails can access this */}
      <Route element={<ProtectedRoute restrictedEmails={LENDER_EMAILS} />}>
        <Route path="/lender-iq" element={<LenderIq />} />
      </Route>

      {/* REGULAR USERS: All routes except lender-iq */}
      <Route element={<ProtectedRoute allowLenderIqForAll={true} />}>
        <Route path="/forms" element={<Forms />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/book-a-demo" element={<BookADemo />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/syllabus-download" element={<Syllabus />} />
        <Route path="/case-study" element={<CaseStudy />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="ebook" element={<EBook />} />
        <Route path="ai-assessment" element={<AIAssessemnt />} />
        <Route path="event" element={<NRF />} />
        <Route path="podcasts" element={<PodcastsPage />} />
        <Route path="/assets" element={<AssetsPage />} />
        <Route path="/lender-iq" element={<LenderIq />} />

      </Route>

    </Routes>
  );
};
