import axios, { AxiosResponse } from 'axios';
import { IForms } from '../types/forms';
export const getForms = (params:any): Promise<AxiosResponse<IForms>> => {
  return axios.get('/forms',{params:{...params}});
};

export const getLenderIqForm = (params?:any): Promise<AxiosResponse<IForms>> => {
  return axios.get('/lender-iq-form',{params:{...params}});
};

export const getExecutiveBriefForm = (params?:any): Promise<AxiosResponse<IForms>> => {
  return axios.get('/ai-in-trumpWorld',{params:{...params}});
};