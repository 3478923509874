import { useState } from "react";
import { AiReadinessFramework } from "./AiReadinessFramework";
import { Checklist } from "./Checklist";
import { Playbook } from "./Playbook";
import { AiUsecase } from "./AiUsecase";
import { Statbook } from "./Statbook";
import { ExecutiveBrief } from "./ExecutiveBrief";

const AssetsView: Record<number, JSX.Element> = {
  1: <AiReadinessFramework />,
  2: <Playbook />,
  3: <Checklist />,
  4: <AiUsecase />,
  5: <Statbook />,
  6: <ExecutiveBrief/>
};

export const AssetsFramework = (): JSX.Element => {
  const [assetsTab, setAssetsTab] = useState(1);

  return (
    <>
      <div className="main-container podcasts">
        <div className="podcasts-tab">
          <div
            className={`item ${assetsTab === 1 ? "active" : ""}`}
            onClick={() => setAssetsTab(1)}
          >
            Framework
          </div>
          <div
            className={`item ${assetsTab === 2 ? "active" : ""}`}
            onClick={() => setAssetsTab(2)}
          >
            Playbook
          </div>
          <div
            className={`item ${assetsTab === 3 ? "active" : ""}`}
            onClick={() => setAssetsTab(3)}
          >
            Checklist
          </div>
          <div
            className={`item ${assetsTab === 4 ? "active" : ""}`}
            onClick={() => setAssetsTab(4)}
          >
            Use Cases
          </div>
          <div
            className={`item ${assetsTab === 5 ? "active" : ""}`}
            onClick={() => setAssetsTab(5)}
          >
            Statbook
          </div>
          <div
            className={`item ${assetsTab === 6 ? "active" : ""}`}
            onClick={() => setAssetsTab(6)}
          >
            Executive Brief
          </div>
        </div>

        {AssetsView[assetsTab]}
      </div>
    </>
  );
};
