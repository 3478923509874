// import { useAuth0 } from "@auth0/auth0-react";
// import axios from "axios";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import LoadingScreen from "../../Common/LoadingScreen";

// const Callback = () => {
//   const { getAccessTokenSilently } = useAuth0();
//   const navigate = useNavigate();
//   const getAccesstoken = async () => {
//     try {
//       const accessToken = await getAccessTokenSilently();
//       axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
//       localStorage.setItem("access_token", accessToken);
//       navigate("/contacts");
//     } catch (error) {
//       console.log("err: ", error);
//     }
//   };

//   useEffect(() => {
//     getAccesstoken();
//   }, []);
//   return <LoadingScreen />;
// };

// export default Callback;


import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../Common/LoadingScreen";
import { LENDER_EMAILS } from "../../../routes";


const Callback = () => {
  const { 
    getAccessTokenSilently, 
    user, 
    isAuthenticated, 
    isLoading, 
    error: authError 
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
 
        if (authError) {
          console.error("Authentication error:", authError);
          navigate("/");
          return;
        }

        if (isLoading) return;

        if (!isAuthenticated) {
          navigate("/");
          return;
        }

        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email",
          },
        });

        // Store token
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        localStorage.setItem("access_token", accessToken);

        // Check if user is a lender
        const userEmail = user?.email?.toLowerCase() || '';
        const isLender = LENDER_EMAILS.some(email => 
          email.toLowerCase() === userEmail
        );

        // Redirect based on user type
        navigate(isLender ? "/lender-iq" : "/contacts", { replace: true });

      } catch (error) {
        console.error("Authentication failed:", error);
        navigate("/", { replace: true });
      }
    };

    handleAuthentication();
  }, [isAuthenticated, isLoading, authError, user]);

  return <LoadingScreen />;
};

export default Callback;